import type { InitialData, Placeholder } from '@omq/types';

import { ConfigWrapper } from './config-wrapper';
import { CookieSessionWrapper } from './cookie-session-wrapper';
import { useInitialData } from '../hooks/initialize';
import { IntegrationStyle } from './integration-style';
import { useCookie } from '../hooks/cookie';

/**
 * Component properties.
 */
export type InitialDataProps<R extends InitialData> = {
  cookieIsEnabled?: boolean;
  cookieName: string;
  params?: {};
  placeholder: Placeholder;
  onReady: (data: R) => JSX.Element;
};

/**
 * Wrapper component that handles initial data loading.
 *
 * @param category - id of category
 * @param cookieIsEnabled - flag to enable/disable cookies
 * @param cookieName - name of the cookie
 * @param onReady - handle result callback
 *
 * @returns {JSX.Element}
 */
export function InitialDataWrapper<T extends InitialData>({
  params,
  cookieIsEnabled,
  cookieName,
  onReady,
  placeholder,
}: InitialDataProps<T>): JSX.Element {
  const [cookie] = useCookie(cookieName);

  // load initial data
  // if cookie is enabled & cookie is already set
  // re-use session
  const data = useInitialData<T>(placeholder, cookie != null ? cookie.session : null, params);

  if (data == null) {
    return <>{'Loading...'}</>;
  }

  return (
    <ConfigWrapper config={data.config}>
      <CookieSessionWrapper
        name={cookieName}
        sessionId={data.session.id}
        cookieIsEnabled={
          cookieIsEnabled != null ? cookieIsEnabled : data.config.isDefaultCookieActive
        }>
        <IntegrationStyle style={data.style} />
        {onReady(data)}
      </CookieSessionWrapper>
    </ConfigWrapper>
  );
}
