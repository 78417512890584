import { useEffect } from 'react';

/**
 * Component properties
 */
type IntegrationStyleProps = {
  style: string;
};

/**
 * Add passed style to head of the document.
 *
 * @param {string} style - css style
 */
export function IntegrationStyle({ style }: IntegrationStyleProps): null {
  useEffect(() => {
    // create style element & add content
    const styleElement = document.createElement('style');
    styleElement.innerHTML = style;

    // add style element to head
    const headElement = document.querySelector('head');
    if (headElement != null) {
      headElement.appendChild(styleElement);
    }

    // remove element on clean up
    return () => {
      styleElement.remove();
    };
  }, [style]);

  // nothing to render
  return null;
}
