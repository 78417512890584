/**
 * Utility functions for css class names
 *
 * @since 10.4
 * @author Jonas Gutenschwager
 */

const integrations = ['omq', 'userlike', 'mapp'];

/**
 * Get css class name for all integrations in one string separated by ', '
 *
 * @param {string} className - className to adapt
 *
 * @returns {string}
 */
export const getCombinedIntegrationClassName = (className: string): string => {
  return integrations
    .map((integration) => getClassNameWithIntegration(integration, className))
    .join(', ');
};

/**
 * Get css class name for passed integration
 *
 * @param {string} integration - integration to adapt
 * @param {string} className - className to adapt
 *
 * @returns {string}
 */
export const getClassNameWithIntegration = (integration: string, className: string): string => {
  return `.${integration}${className}`;
};

/**
 * Check if element matches class name for any integration
 *
 * @param element element to check
 * @param className class name to check
 */
export const matchIntegrationClassName = (element: Element, className: string): boolean => {
  return integrations.some((integration) => {
    element.matches(getClassNameWithIntegration(integration, className));
  });
};
