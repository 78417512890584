import type { DOMEvent } from '@omq/types';

import type { FormField } from './form_field';

import { setStyle } from '../utils/helper';
import { CONDITION_CSS } from '../conditional-answer/conditional-answer';

/**
 * Class that handles form validation.
 *
 * @class
 * @author Florian Walch
 * @since 9.1
 */
export default class FormValidation {
  /**
   * Form DOM element.
   *
   * @type {HTMLFormElement}
   */
  form: HTMLFormElement;

  errorElement: HTMLElement | null | undefined = null;

  /**
   * Constructor
   *
   * @param form
   */
  constructor(form: HTMLFormElement) {
    this.form = form;

    this.initFormElements();
  }

  /**
   * Add event handlers to form elements.
   */
  initFormElements(): void {
    // iterate through form elements
    Array.prototype.forEach.call(this.form.elements, (element) => {
      element.addEventListener('invalid', this.handleInvalidEvent);

      element.addEventListener('input', this.handleInputEvent);

      element.addEventListener('focus', this.handleFocusEvent);

      element.addEventListener('blur', this.handleBlurEvents);
    });

    // add click handler to all condition option buttons
    // to reset invalid flag
    Array.from(this.form.querySelectorAll(`.${CONDITION_CSS.OPTION}`)).forEach((option) => {
      option.addEventListener('click', this.handleConditionOptionClick);
    });
  }

  unsubscribeElements() {
    // iterate through form elements
    Array.prototype.forEach.call(this.form.elements, (element) => {
      element.removeEventListener('invalid', this.handleInvalidEvent);

      element.removeEventListener('input', this.handleInputEvent);

      element.removeEventListener('focus', this.handleFocusEvent);

      element.removeEventListener('blur', this.handleBlurEvents);
    });

    // remove click handler from condition option buttons
    Array.from(this.form.querySelectorAll(`.${CONDITION_CSS.OPTION}`)).forEach((option) => {
      option.removeEventListener('click', this.handleConditionOptionClick);
    });
  }

  /**
   * Validate form by checking validity of form elements,
   * validity of conditions and validity of textarea elements.
   */
  validate: () => boolean = (): boolean => {
    // return valid state of form
    const formIsValid = this.form.checkValidity();
    const conditionsAreValid = this.checkConditionValidity();
    const textAreasValid = Array.from(this.form.querySelectorAll('textarea')).every((element) => {
      return this.textAreaCheckValidity(element);
    });

    return formIsValid && conditionsAreValid && textAreasValid;
  };

  /**
   * Handle invalid events. Is triggered if checkValidity is called,
   * or user tries to submit the form.
   *
   * @param {DOMEvent} event
   */
  handleInvalidEvent: (event: DOMEvent<FormField>) => void = (event) => {
    const element = event.currentTarget;

    // add invalid style
    element.classList.add('invalid');

    this.displayErrorMessageFor(element);

    // do not do browser default stuff (display native error message)
    event.preventDefault();
  };

  /**
   * Handle input events (content changes).
   * Check if input is valid, and remove error style/message.
   *
   * @param {DOMEvent} event
   */
  handleInputEvent: (event: DOMEvent<FormField>) => void = (event) => {
    const element = event.currentTarget;

    // if field is valid
    if (this.textAreaCheckValidity(element)) {
      // remove message
      this.removeErrorMessage();

      // remove style
      element.classList.remove('invalid');
    }
  };

  /**
   * Handle focus events.
   * If field is invalid on focus, display error box.
   *
   * @param {DOMEvent} event
   */
  handleFocusEvent: (event: DOMEvent<FormField>) => void = (event) => {
    const element = event.currentTarget;

    if (!this.textAreaCheckValidity(element)) {
      this.displayErrorMessageFor(element);
    }
  };

  /**
   * Handle blur events.
   *
   * If field is invalid on blur, check if its valid.
   * Remove error message.
   *
   * @param {DOMEvent} event
   */
  handleBlurEvents: (event: DOMEvent<FormField>) => void = (event) => {
    const element = event.currentTarget;

    this.textAreaCheckValidity(element);

    // remove message
    this.removeErrorMessage();
  };

  /**
   * Reset invalid state for condition options after they have been clicked/selected.
   *
   * @param event
   */
  handleConditionOptionClick: (event: MouseEvent) => void = (event) => {
    const option = event.currentTarget;
    if (!(option instanceof HTMLElement)) {
      return;
    }

    const conditionOptions = option.parentElement;
    if (conditionOptions == null) {
      return;
    }

    // remove invalid class from all options
    Array.from(conditionOptions.children).forEach((option) => {
      option.classList.remove('invalid');
    });
  };

  /**
   * Check if textarea pattern is valid.
   *
   * @param element - form element
   * @returns {boolean} - true if valid
   */
  textAreaCheckValidity(element: FormField): boolean {
    if (element instanceof HTMLTextAreaElement && element.getAttribute('pattern')) {
      const pattern = element.getAttribute('pattern') || '';
      let result = new RegExp(`^(?:${pattern})$`).test(element.value);

      if (!result) {
        const evt = new Event('invalid', {
          bubbles: true,
          cancelable: false,
          composed: true,
        });
        element.dispatchEvent(evt);
      }

      return result && element.checkValidity();
    } else {
      // if not a textarea, use default check
      return element.checkValidity();
    }
  }

  /**
   * Check if all visible & required conditions are valid/have a selection
   */
  checkConditionValidity(): boolean {
    const { form } = this;

    let isValid = true;

    // go through all required conditions
    Array.from(form.querySelectorAll(`.${CONDITION_CSS.ROOT} input[type=hidden]`)).forEach(
      (conditionValueField) => {
        if (!(conditionValueField instanceof HTMLInputElement)) {
          return;
        }

        // only handle fields inside conditions
        const condition = conditionValueField.closest(`.${CONDITION_CSS.ROOT}`);

        if (condition == null) {
          return;
        }

        // dont validate disabled fields
        if (conditionValueField.disabled || !conditionValueField.required) {
          return;
        }

        // if value is empty, no selection has been made
        if (conditionValueField.value === '') {
          // set valid flag to false
          // prevents form submit
          isValid = false;

          // get condition options
          const conditionOptions = Array.from(condition.children).find((child) =>
            child.matches('.' + CONDITION_CSS.OPTIONS),
          );

          if (conditionOptions != null) {
            // set invalid class to all condition buttons
            Array.from(conditionOptions.children).forEach((option) =>
              option.classList.add('invalid'),
            );
          }
        }
      },
    );

    // return flag
    return isValid;
  }

  /**
   * Display error message for passed HTMLElement.
   *
   * @param {FormField} element - Form element
   */
  displayErrorMessageFor(element: FormField): void {
    // remove existing error box
    this.removeErrorMessage();

    // get message from attribute
    let message = element.getAttribute('data-validate-error-message');

    // if there is no message, get default validation message
    // istanbul ignore else
    if (message == null) {
      message = element.validationMessage;
    }

    const parentElement = element.parentElement;
    if (parentElement == null) {
      throw new Error('Element needs parent element to show error message.');
    }

    // create error box
    const errorElement = document.createElement('div');
    errorElement.className = 'form-error-message';
    errorElement.innerText = message;

    // get dimensions from parent element, to position error element
    const parentRect = parentElement.getClientRects()[0];
    errorElement.style.top = `${parentRect.height + 14}px`;
    errorElement.style.left = `0px`;

    // istanbul ignore else
    if (parentElement instanceof HTMLElement) {
      // set parent to relative (message box is absolute positioned).
      setStyle(parentElement, {
        position: 'relative',
      });
    }

    // add error box
    parentElement.insertBefore(errorElement, element.nextSibling);
    this.errorElement = errorElement;
  }

  /**
   * Remove error message box.
   */
  removeErrorMessage(): void {
    // remove if element exists
    if (this.errorElement) {
      this.errorElement.remove();
    }

    // reset to null, for later checks
    this.errorElement = null;
  }
}
