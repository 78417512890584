import type { ErrorDetails } from '@omq/shared';
import { ErrorHandling, getBaseUrl } from '@omq/shared';
import type { Placeholder } from '@omq/types';

import { DSN } from '../error/sentry';

declare global {
  interface Window {
    _oh?: HelpConfiguration;
    userlikeHelp?: HelpConfiguration;
    mappHelp?: HelpConfiguration;
    OMQHelpPlaceholders?: Placeholder;
    UserlikePlaceholders?: Placeholder;
    OMQHelpCustomValues?: Placeholder;
    UserlikeCustomValues?: Placeholder;
    MappPlaceholders?: Placeholder;
    MappCustomValues?: Placeholder;
  }
}

/**
 * Type of Help configuration.
 */
export type HelpConfiguration = {
  container: string;
  account: string | null | undefined;
  apiKey: string | null | undefined;
  cookieIsEnabled?: boolean;
  query?: string;
  questionId?: number;
  config?: HelpConfiguration;
};

/**
 * Default css selector for help container
 *
 * @type {string}
 */
const DEFAULT_HELP_CONTAINER =
  '#omq-help-container, #userlike-help-container, #mapp-help-container';

const HelpConfigAttributeNames = {
  ACCOUNT: 'help-account',
  APIKEY: 'help-api-key',
  COOKIE_ENABLED: 'help-cookie-enabled',
  QUERY: 'help-query',
  QUESTION_ID: 'help-question-id',
};

// get element attribute for given name
// check for different names depending on integration
function getDataAttribute(element, attributeName) {
  return (
    element.getAttribute(`data-omq-${attributeName}`) ||
    element.getAttribute(`data-userlike-${attributeName}`) ||
    element.getAttribute(`data-mapp-${attributeName}`) ||
    null
  );
}

// get element attribute for given name
// check for different names depending on integration
function setDataAttribute(element, attributeName, value) {
  // userlike
  if (element.hasAttribute(`data-userlike-${attributeName}`)) {
    element.setAttribute(`data-userlike-${attributeName}`, value);
  }
  // mapp
  else if (element.hasAttribute(`data-mapp-${attributeName}`)) {
    element.setAttribute(`data-mapp-${attributeName}`, value);
  }
  // omq
  else {
    element.setAttribute(`data-omq-${attributeName}`, value);
  }
}

/**
 * Load style sheet for the passed account.
 * Adds a link stylesheet tag to the head element.
 *
 * @param {string} account - name/url of account
 * @param {string} file - name of stylesheet
 */
export function loadStyleSheet(account: string, file: string = 'help'): void {
  const link = document.createElement('link');
  const head = document.querySelector('head');

  // check if head is available
  if (head == null) {
    return;
  }

  // remove existing stylesheet
  const existingStyle = document.querySelector('#omq-help-stylesheet');
  if (existingStyle != null) {
    return;
  }

  // set link properties
  link.href = `${getBaseUrl(account) || ''}/help/${file}.min.css`;
  link.rel = 'stylesheet';
  link.id = 'omq-help-stylesheet';

  // append to head
  head.appendChild(link);
}

/**
 * Read Help configuration from global var.
 *
 * Global Help configuration looks like this:
 *   _oh = {
 *     container: 'CSS_SELECTOR', // default #omq-help-container
 *     account: 'NAME|URL',
 *     apiKey: 'API_KEY',
 *   };
 *
 * @returns {?HelpConfiguration}
 */
export function readConfigurationFromGlobal(): HelpConfiguration | null | undefined {
  const config = window.userlikeHelp || window.mappHelp || window._oh;

  // check if config is set
  if (config == null) {
    return null;
  }

  // get values
  const { container, account, apiKey, cookieIsEnabled } = config;

  // return config
  return {
    container: container || DEFAULT_HELP_CONTAINER,
    account,
    apiKey,
    cookieIsEnabled,
  };
}

/**
 * Read Help config from the HTML element.
 *
 * The element with help configuration looks like this:
 *   <div id="omq-help-container"
 *        data-omq-help-account="NAME|URL"
 *        data-omq-help-api-key="API_KEY">
 *   </div>
 *
 * @returns {?HelpConfiguration}
 */
export function readConfigurationFromAttributes(): HelpConfiguration | null | undefined {
  // get element
  const element = document.querySelector(DEFAULT_HELP_CONTAINER);
  if (element == null) {
    return null;
  }

  // get attributes
  const account = getDataAttribute(element, HelpConfigAttributeNames.ACCOUNT);
  const apiKey = getDataAttribute(element, HelpConfigAttributeNames.APIKEY);
  const cookieIsEnabled = getDataAttribute(element, HelpConfigAttributeNames.COOKIE_ENABLED);
  const query = getDataAttribute(element, HelpConfigAttributeNames.QUERY);
  const questionId = getDataAttribute(element, HelpConfigAttributeNames.QUESTION_ID);

  // return config
  return {
    container: DEFAULT_HELP_CONTAINER,
    account,
    apiKey,
    cookieIsEnabled: cookieIsEnabled != null ? cookieIsEnabled === 'true' : undefined,
    query: query != null ? query : undefined,
    questionId: questionId != null ? parseInt(questionId) : undefined,
  };
}

/**
 * Load configuration and validate.
 *
 * @returns {HelpConfiguration}
 */
export function readConfiguration(): HelpConfiguration {
  // read config
  const config = readConfigurationFromGlobal() || readConfigurationFromAttributes();

  // check if config is set
  if (!config) {
    throw new Error(
      `OMQ Help: Error in integration. No configuration found. Please check the documentation on how to integrate OMQ Help.`,
    );
  }

  // get properties
  const { container, account, apiKey } = config;

  // get element
  const element = document.querySelector(container);
  if (!element) {
    throw new Error(`OMQ Help: Error in integration. No HTML element was found for $\{element} .`);
  }

  // check api key
  if (apiKey == null) {
    throw new Error(
      'OMQ Help: Error in integration. Help API Key is missing. Add attribute `data-omq-help-api-key=HELP_API_KEY` to your help container.',
    );
  }

  // check account
  if (account == null) {
    throw new Error(
      'OMQ Help: Error in integration. Account name is missing. Add attribute `data-omq-help-account=ACCOUNT_NAME` to your help container.',
    );
  }

  return config;
}

/**
 * Update configuration.
 *
 * @param {?HelpConfiguration} config - help config
 */
export function updateConfiguration(config: HelpConfiguration | null | undefined): void {
  if (config == null) {
    return;
  }

  // if config has been set via global object
  if (window._oh != null) {
    // update global object
    window._oh = { ...window._oh, config };
    return;
  } else if (window.userlikeHelp != null) {
    // update global object
    window.userlikeHelp = { ...window.userlikeHelp, config };
    return;
  } else if (window.mappHelp != null) {
    // update global object
    window.mappHelp = { ...window.mappHelp, config };
    return;
  }
  // otherwise update container attributes

  // get container element
  const element = document.querySelector(DEFAULT_HELP_CONTAINER);
  if (element == null) {
    return;
  }

  const { account, apiKey, cookieIsEnabled, query, questionId } = config;

  // update account
  if (account != null) {
    setDataAttribute(element, HelpConfigAttributeNames.ACCOUNT, account);
  }

  // update apikey
  if (apiKey != null) {
    setDataAttribute(element, HelpConfigAttributeNames.APIKEY, apiKey);
  }

  // update query
  if (query != null) {
    setDataAttribute(element, HelpConfigAttributeNames.QUERY, query);
  }

  // update question id
  if (questionId != null) {
    setDataAttribute(element, HelpConfigAttributeNames.QUESTION_ID, questionId.toString());
  }

  // update cookie
  if (cookieIsEnabled != null) {
    setDataAttribute(
      element,
      HelpConfigAttributeNames.COOKIE_ENABLED,
      cookieIsEnabled ? 'true' : 'false',
    );
  }
}

/**
 * Handle error received from error-boundary.
 *
 * @param {Error} error - Error object
 * @param {?ErrorDetails} details - additional information
 */
export function handleError(error: Error, details: ErrorDetails | null | undefined) {
  ErrorHandling.captureException(error, details, DSN);
}

/**
 * Read answer placeholder from the global object if set.
 *
 * @returns {Placeholder}
 */
export function readPlaceholder(): Placeholder {
  const placeholders =
    window.OMQHelpPlaceholders ||
    window.UserlikePlaceholders ||
    window.MappPlaceholders ||
    window.MappPlaceholders ||
    window.OMQHelpCustomValues ||
    window.UserlikeCustomValues ||
    window.MappCustomValues ||
    {};

  // clean props
  return validatePlaceholders(placeholders);
}

/**
 * Check given placeholders and remove invalid keys/values.
 *
 * @param placeholders
 *
 * @returns {Placeholder}
 */
export function validatePlaceholders(placeholders: {} = {}): Placeholder {
  // clean props
  return Object.keys(placeholders).reduce((result, key) => {
    if (typeof key === 'string') {
      result[key] = placeholders[key];
    }
    return result;
  }, {});
}

/**
 * Return error view to display in case of an error.
 *
 * @param {Error} error - Error to display
 *
 * @returns JSX.Element
 */
export function renderError(error: Error): JSX.Element {
  return (
    <div className="omq-help error-boundary">
      <h1 className="error-boundary__headline">Backend error</h1>
      <p className="error-boundary__message">{error.message}</p>
    </div>
  );
}
