import { createContext, useContext } from 'react';
import { EventDispatcher } from '../event/event-dispatcher';
import { OMQSubType } from '@omq/types/src/stub/subtype';

/**
 * Type for component properties.
 */
type EventWrapperProps<E extends EventDispatcher<any>> = {
  children: JSX.Element;
  events: EventDispatcher<any>;
};

const EventContext = createContext<OMQSubType<EventDispatcher<any>>>(new EventDispatcher(document));

/**
 * Context helper function.
 * Used by components to get app event context.
 *
 * @returns {EventContext}
 */
export function useEventContext<T extends EventDispatcher<any>>(): T {
  return useContext<T>(EventContext);
}

/**
 * Render context provider for event context.
 *
 * @param {EventWrapperProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {JSX.Element}
 */
export function EventWrapper<E extends EventDispatcher<any>>(
  props: EventWrapperProps<E>,
): JSX.Element {
  const { children, events } = props;

  return <EventContext.Provider value={events}>{children}</EventContext.Provider>;
}
