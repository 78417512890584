import { documentReady, ErrorHandling } from '@omq/shared';
import { createHelp } from './src/index';

import { DSN } from './src/error/sentry';

// create help module after document is ready
documentReady().then(() => {
  try {
    createHelp();
  } catch (error) {
    if (error.message != null && error.message !== '') {
      console.error(
        `%c${error.message}`,
        'font-family:monospace, font-size:16px; color: red; padding: 10px;',
      );
    }

    // catch unhandled errors from application
    ErrorHandling.captureException(error, null, DSN);
  }
});
