import type { AutoCompleteItem as AutoCompleteItemType } from '@omq/types';
import { useConfigContext } from '@omq/shared';

import './auto-complete.less';

/**
 * Type for component properties
 */
type AutoCompleteProps = {
  /**
   * Items to display in component
   */
  items: Array<AutoCompleteItemType>;

  activeItemIndex: number;

  /**
   * Handler to call after selection.
   * Selected text will be passed
   *
   * @param {string} text - text of selected item
   */
  onAutoCompleteSelect: (text: string) => void;
};

type AutoCompleteItemProps = {
  item: AutoCompleteItemType;
  isActive: boolean;
  onClick: (text: string) => void;
};

const MAX_AUTO_COMPLETE_TAGS = 3;

const TAG_PREFIX = '...';

/**
 * Renders a single item.
 *
 * @param {AutoCompleteItem} item - Item to render
 * @param {boolean} isActive - Flag if item is active
 * @param {Function} onClick - click handler
 *
 * @returns {JSX.Element}
 */
export function AutoCompleteItem({ item, isActive, onClick }: AutoCompleteItemProps): JSX.Element {
  const config = useConfigContext();

  const { query: tags } = item;
  const tagCount = tags.length;

  // combine all tags to a text
  let text = tags
    .slice(MAX_AUTO_COMPLETE_TAGS * -1)
    .map((tag) => tag.content)
    .join(' ');

  // build css class name
  const className = `${config.generateClassName('auto-complete__item')} ${
    isActive ? config.generateClassName('auto-complete__item--active') : ''
  }`;

  // add prefix if tag count exceeded
  if (tagCount > MAX_AUTO_COMPLETE_TAGS) {
    text = `${TAG_PREFIX} ${text}`;
  }

  // return item with text and click handler
  return (
    <div
      key={text}
      className={className}
      onMouseDown={(event) => event.preventDefault()} // this will prevent the blur event on search input
      onClick={() => onClick(text)}>
      {text}
    </div>
  );
}

/**
 * Help auto complete component.
 * Displays suggested items for search.
 * Selected item text will be passed to `onAutoCompleteSelect` prop.
 *
 * Example usage:
 *  <AutoComplete
 *    items={items}
 *    activeItemIndex={idx}
 *    onAutoCompleteSelect={handler}
 *    />
 *
 * @author Florian Walch
 * @since 9.2
 *
 * @returns {JSX.Element}
 */
export function AutoComplete(props: AutoCompleteProps): JSX.Element {
  const config = useConfigContext();

  // get props
  const { items, activeItemIndex, onAutoCompleteSelect } = props;

  // do not render if there are no items
  if (items.length === 0) {
    return null;
  }

  // render wrapper with items
  return (
    <div className={config.generateClassName('auto-complete')}>
      {items.map((item, idx) => (
        <AutoCompleteItem
          key={idx}
          item={item}
          isActive={idx === activeItemIndex}
          onClick={onAutoCompleteSelect}
        />
      ))}
    </div>
  );
}
