const DEFAULT_DELAY = {
  HELP: 500, // 500 ms initial delay, when it gets bigger than above
  CONTACT: 1500, // 1500 ms (1.5 second) standard delay
  ASSIST: 500,
};

const MIN_LENGTH = {
  HELP: 30, // 30 characters, 5~6 words
  CONTACT: 600, // 600 chars, roughly 3~6 paragraphs.
  ASSIST: 30, // 30 characters, 5~6 words
};

const GROWTH_RATE = 1.1;

export enum SearchDelayTypes {
  CONTACT = 'CONTACT',
  HELP = 'HELP',
  ASSIST = 'ASSIST',
}

/**
 * Classical growth function
 *
 * @param {number} initialValue - initial value
 * @param {number} growth - growth rate
 * @param {number} input - input length
 *
 * @returns {number}
 */
const growth = (initialValue: number, growth: number, input: number): number => {
  return initialValue * Math.pow(growth, input);
};

/**
 * Delay function for search in contact & help.
 * Returns ideal delay value in ms for the given content length
 *
 * @param {number} value - number of characters
 * @param {SearchDelayTypes} type - type of search
 *
 * @returns {number} - time of delay in ms.
 */
export const calculateSearchDelay = (value: number, type: SearchDelayTypes): number => {
  const delay = DEFAULT_DELAY[type];
  const minLength = MIN_LENGTH[type];

  // if less than min length
  if (value < minLength) {
    // for contact, we induce standard delay
    // for help, we induce no delay
    return [SearchDelayTypes.HELP, SearchDelayTypes.ASSIST].includes(type) ? 0 : delay;
  }

  // for anything above standard length, we introduce delay with
  return Math.round(growth(delay, GROWTH_RATE, value / minLength - 1));
};
