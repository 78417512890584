import { ConfigURLPatternTypes } from '@omq/types';

/**
 * Type for help url params
 */
export type HelpURLParams = {
  category: number | string | null;
  question: number | string | null;
  query: string | null;
  title: string | null;
};

type URLChangeHandler = () => void;

export type URLChangeCallback = (urlParams: HelpURLParams) => void;

const QUESTION_SEPARATOR = '-q-';
const CATEGORY_SEPARATOR = '-c-';

const QUESTION_REGEX = new RegExp(/[a-zA-Z\\-]-q-[0-9]/);
const CATEGORY_REGEX = new RegExp(/[a-zA-Z\\-]-c-[0-9]/);

/**
 * URL params used by OMQ Help.
 */
const HELP_URL_PARAM = {
  CATEGORY: 'category',
  QUESTION: 'question',
  QUERY: 'query',
  TITLE: 'title',
};

const HELP_URL_PARAMS = [
  HELP_URL_PARAM.CATEGORY,
  HELP_URL_PARAM.QUESTION,
  HELP_URL_PARAM.QUERY,
  HELP_URL_PARAM.TITLE,
];

/**
 * Store for URL history functions.
 *
 * @author Florian Walch
 * @since 9.2
 */
export const URLStore = {
  /**
   * Register for url history changes.
   * Call callback with history state or current url params
   *
   * @param {Function} callback - Function that should be called if url changes.
   */
  registerURLParamChanges: (callback: URLChangeCallback): URLChangeHandler => {
    const urlChangeHandler = () => {
      // get params
      const { question, category, query, title } = URLStore.readURLParams();

      // call callback
      callback({
        question,
        category,
        query,
        title,
      });
    };

    // add listener
    window.addEventListener('popstate', urlChangeHandler);

    // return listener
    return urlChangeHandler;
  },

  /**
   * Remove listener
   */
  unregisterURLParamChanges: (handler: URLChangeHandler): void => {
    window.removeEventListener('popstate', handler);
  },

  /**
   * Reads help params from current URL.
   * (Get params from either url parameters or pathname)
   *
   * @param {ConfigURLPatternTypes} [urlPatternType] - pattern of URL
   *
   * @returns {HelpURLParams}
   */
  readURLParams: (urlPatternType?: ConfigURLPatternTypes): HelpURLParams => {
    const { search, pathname } = window.location;

    // if the type is not available yet, try to detect the type.
    const patternType = urlPatternType != null ? urlPatternType : URLStore.detectURLPatternType();

    // for query param type, do like before
    if (patternType === ConfigURLPatternTypes.QUERY_PARAM) {
      // get params
      const urlParams = new URLSearchParams(search);
      const category = urlParams.get(HELP_URL_PARAM.CATEGORY);
      const question = urlParams.get(HELP_URL_PARAM.QUESTION);
      const query = urlParams.get(HELP_URL_PARAM.QUERY);

      return {
        category: category != null ? parseInt(category) : null,
        question: question != null ? parseInt(question) : null,
        query,
        title: urlParams.get(HELP_URL_PARAM.TITLE),
      };
    }

    // otherwise check pathname

    // get the last part of url path
    const urlParts = pathname.split('/');
    const helpPath = urlParts[urlParts.length - 1];

    // check if it fits the question pattern
    if (QUESTION_REGEX.test(helpPath)) {
      const [title, question] = helpPath.split(QUESTION_SEPARATOR);
      return {
        title,
        question,
        query: null,
        category: null,
      };
    } // check if it fits the category pattern
    else if (CATEGORY_REGEX.test(helpPath)) {
      const [title, category] = helpPath.split(CATEGORY_SEPARATOR);
      return {
        title,
        category,
        query: null,
        question: null,
      };
    }

    // return default
    return {
      category: null,
      question: null,
      query: null,
      title: null,
    };
  },

  /**
   * Pushes a new URL/State to the browser history
   * for the passed URL parameters.
   *
   * @param {string} path - url path of entity
   * @param {ConfigURLPatternType} [urlPatternType] - pattern of url entries
   */
  writeURL: (
    path: string,
    urlPatternType: ConfigURLPatternTypes = ConfigURLPatternTypes.QUERY_PARAM,
  ): void => {
    // get info of current location
    const { search } = window.location;

    let url;

    // if passed path is url query param
    // merge with existing ones
    if (urlPatternType === ConfigURLPatternTypes.QUERY_PARAM) {
      const currentSearchParams = new URLSearchParams(search);
      const newSearchParams = new URLSearchParams(path === '/' ? '' : path);

      // loop through current query params
      currentSearchParams.forEach((value, key) => {
        // keep query params that are not part of help
        if (!HELP_URL_PARAMS.includes(key)) {
          newSearchParams.append(key, value);
        }
      });

      // build url with new query params
      url = URLStore.buildAbsolutePath(`?${newSearchParams.toString()}`);
    } else {
      // build url with the given path & add existing query params
      url = `${URLStore.buildAbsolutePath(path)}${search}`;
    }

    // push to history
    window.history.pushState(null, null, url);
  },

  /**
   * Build the absolute path from a passed relative path.
   *
   * @param {string} url - relative url.
   *
   * @returns {string}
   */
  buildAbsolutePath: (url: string): string => {
    // get info of current location
    const { origin, pathname } = window.location;

    // remove trailing slash
    const path =
      pathname[pathname.length - 1] === '/' ? pathname.substr(0, pathname.length - 1) : pathname;

    // get the last part of url path
    const urlParts = path.split('/');
    const lastPart = urlParts[urlParts.length - 1];

    let base;

    // remove question or category path
    // is already part of the passed url
    if (QUESTION_REGEX.test(lastPart) || CATEGORY_REGEX.test(lastPart)) {
      base = urlParts.slice(0, urlParts.length - 1).join('/');
    } else {
      base = urlParts.join('/');
    }

    // build absolute url
    return `${origin}${base}${url}`;
  },

  /**
   * Check which type of URL pattern to use.
   *
   * @returns {string}
   */
  detectURLPatternType(): ConfigURLPatternTypes {
    // get info of current location
    const { pathname } = window.location;

    // if pathname fits one of the patterns,
    // use the PATH type
    if (QUESTION_REGEX.test(pathname) || CATEGORY_REGEX.test(pathname)) {
      return ConfigURLPatternTypes.PATH;
    }

    // otherwise use default query param type.
    return ConfigURLPatternTypes.QUERY_PARAM;
  },
};
