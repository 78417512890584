/**
 * Constant values for keyboard keys.
 */
export default {
  // keyboard events
  KEYBOARD_KEYS: {
    ENTER: 'Enter',
    ARROW_UP: 'ArrowUp',
    ARROW_DOWN: 'ArrowDown',
    ARROW_LEFT: 'ArrowLeft',
    ARROW_RIGHT: 'ArrowRight',
  },

  KEYBOARD_KEY_CODES: {
    Enter: 13,
    ArrowLeft: 37,
    ArrowUp: 38,
    ArrowRight: 39,
    ArrowDown: 40,
  },
};
