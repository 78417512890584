import { useState, useEffect, useRef } from 'react';

import type { ServerError } from '@omq/shared';
import { Backend, useBackendContext } from '@omq/shared';
import type { Placeholder } from '@omq/types';

import type { HelpPage } from '../api/help';
import { HelpAPI } from '../api/help';
import { DEFAULT_LANGUAGE } from '../index';

const defaultPage = {
  categories: [],
  categoryPath: [],
  questions: [],
  nextPage: -1,
  language: DEFAULT_LANGUAGE,
  faqSnippet: null,
};

/**
 * Load help page from server with passed attributes.
 *
 * @param {number|null} category - current category
 * @param {number|null} nextPage - nr of page
 * @param {HelpPage} initialHelpPage - preloaded page
 * @param {Placeholder} placeholder - placeholder for answers
 *
 * @returns {HelpPage|ServerError}
 */
export function useHelpPage(
  category: number | null,
  nextPage: number,
  initialHelpPage: HelpPage | null,
  placeholder: Placeholder,
): [HelpPage, ServerError | null] {
  // create boolean ref to skip first call of effect
  const mountRef = useRef(false);

  // setup state
  const [helpPage, setHelpPage] = useState<HelpPage>(initialHelpPage || defaultPage);
  const [error, setError] = useState<ServerError | null>(null);

  const backend = useBackendContext();

  // setup effect
  useEffect(() => {
    // do not load on initial call, if help page already has been loaded
    // only load on refresh
    if (initialHelpPage != null && mountRef.current === false) {
      mountRef.current = true;
      return;
    }

    // create cancel token
    const cancelSource = Backend.getCancelToken();

    // make API call
    HelpAPI.page(backend, category, nextPage, placeholder, cancelSource.token)
      .then((result) => {
        // merge help page result
        setHelpPage((currentPage) => {
          // if initial page was loaded
          // use result
          if (nextPage === -1) {
            return result;
          }

          // if next page was loaded
          return {
            ...currentPage,
            // keep categories and path
            nextPage: result.nextPage,
            // update next page
            questions: [...currentPage.questions, ...result.questions], // merge questions
          };
        });
        setError(null);
      })
      .catch((error) => {
        setError(error);
        setHelpPage(defaultPage);
      });

    // cancel request on recall or unmount
    return () => {
      if (cancelSource != null) {
        cancelSource.cancel('Request canceled');
      }
    };
  }, [backend, category, nextPage, initialHelpPage, placeholder]);

  return [helpPage, error];
}
