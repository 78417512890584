import { Fragment } from 'react';
import { useConfigContext } from '@omq/shared';

import { Search } from '../components/search/search';

/**
 * Type for component properties.
 */
type ErrorViewProps = {
  errorMessage: string;
};

/**
 * Show errors.
 *
 * @param {ErrorViewProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {JSX.Element}
 */
export function ErrorView(props: ErrorViewProps): JSX.Element {
  const config = useConfigContext();

  return (
    <Fragment>
      <div className={config.generateClassName('help-container')}>
        <Search value="" isDisabled={true} autoCompleteItems={[]} onChange={() => {}} />
      </div>

      <div className={config.generateClassName('help-container')}>
        <div className="error-view">{props.errorMessage}</div>
      </div>
    </Fragment>
  );
}
