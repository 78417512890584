import type { Question } from '@omq/types';

export const EventNames = {
  QUESTION_CLICK_EVENT: 'onOmqQuestionClick',
  SEARCH_LOADED_EVENT: 'onOmqSearchLoaded',
  CATEGORIES_LOADED_EVENT: 'onOmqCategoriesLoaded',
};

/**
 * Class that dispatches custom event on provided element.
 *
 * @class
 * @since 9.3
 * @author Florian Walch
 */
export class EventDispatcher<S> {
  // help element
  element: (HTMLElement | null | undefined) | Document;

  /**
   * Constructor.
   *
   * @param {HTMLElement} element - element that dispatches events
   */
  constructor(element: (HTMLElement | null | undefined) | Document) {
    this.element = element;
  }

  /**
   * Dispatch event for given name.
   *
   * @param {string} eventName - event name
   * @param {{}} detail - event details
   */
  dispatchEvent(eventName: string, detail: {}) {
    const { element } = this;
    if (element == null) {
      return;
    }

    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail,
    });
    element.dispatchEvent(event);
  }

  /**
   * Dispatch a question that has been clicked.
   *
   * @param {Question} question - clicked question
   */
  dispatchQuestionClick(question: Question) {
    this.dispatchEvent(EventNames.QUESTION_CLICK_EVENT, {
      question,
    });
  }

  /**
   * Dispatch event after search result came in
   * and pass result to subscribers.
   */
  dispatchSearchLoaded(searchValue: string, result: S) {
    this.dispatchEvent(EventNames.SEARCH_LOADED_EVENT, {
      searchValue,
      ...result,
    });
  }
}
