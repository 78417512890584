import { useState, useEffect } from 'react';
import { Placeholder } from '@omq/types';
import { ServerError, useBackendContext, useConfigContext } from '@omq/shared';

import { QuestionItem } from './question-item';
import type { HelpQuestion } from '../../api/help';
import { HelpAPI } from '../../api/help';

import './question-list.less';

/**
 * Type for component properties.
 */
type QuestionListProps = {
  questions: Array<HelpQuestion>;
  selectedQuestionId: number | null;
  onQuestionClick?: (question: HelpQuestion | null) => void;
  renderEmptyList?: (() => JSX.Element) | null;
  isInDetailView?: boolean;
  isEmptyCategory?: boolean;
  placeholder?: Placeholder;
  onError?: (error: ServerError) => void;
};

/**
 * Question List Component.
 * Renders a list of questions.
 *
 * Calls `onQuestionClick` prop function for selection changes.
 *
 * @see QuestionItem
 *
 * @param {QuestionListProps} props - Component properties
 * @returns {JSX.Element}
 */
export function QuestionList({
  questions,
  selectedQuestionId,
  onQuestionClick,
  renderEmptyList,
  isInDetailView,
  isEmptyCategory,
  placeholder,
  onError,
}: QuestionListProps): JSX.Element {
  const [selectedQuestion, setSelectedQuestion] = useState<HelpQuestion | null>(null);

  const backend = useBackendContext();
  const config = useConfigContext();

  // loads the selected question if not already there
  useEffect(() => {
    // reset selected question if id is null
    if (selectedQuestionId == null) {
      setSelectedQuestion(null);
      return;
    }

    // find selected question
    const currentQuestion = questions.find((item) => item.id === selectedQuestionId);

    // Lazy load question content on click if answers have not been loaded yet
    if (currentQuestion && !currentQuestion.answers) {
      HelpAPI.question(backend, selectedQuestionId, placeholder)
        .then(async (result) => {
          // Set answers to the question
          setSelectedQuestion(result);

          onError(null);
        })
        .catch((error) => {
          console.error('Error loading question: ', selectedQuestionId);
          onError(error);
        });
    } // sets the selected question
    else {
      setSelectedQuestion(currentQuestion);
    }
  }, [selectedQuestionId]);

  let emptyList = null;

  // if list is empty
  if (questions.length === 0) {
    // check if render prop is set
    if (renderEmptyList != null) {
      // call render prop
      emptyList = renderEmptyList();
    } else {
      // differentiate between empty category and empty search result
      if (isEmptyCategory) {
        // render empty category empty list
        emptyList = (
          <div className={config.generateClassName('question-list__empty')}>
            {config.loc('emptyCategory')}
          </div>
        );
      } else {
        // render default empty list
        emptyList = (
          <div className={config.generateClassName('question-list__empty')}>
            {config.loc('emptylist')}
          </div>
        );
      }
    }
  }

  // render list with items
  return (
    <div
      className={config.generateClassName('question-list')}
      aria-live="polite"
      aria-relevant="text">
      {emptyList != null
        ? emptyList
        : questions.map((question) => (
            <QuestionItem
              isInDetailView={!!isInDetailView}
              key={question.id}
              question={question.id === selectedQuestion?.id ? selectedQuestion : question}
              isActive={selectedQuestion?.id === question.id}
              onQuestionClick={onQuestionClick}
            />
          ))}
    </div>
  );
}
