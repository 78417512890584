import { Fragment } from 'react';
import { ConfigAnswerDisplayTypes, Placeholder } from '@omq/types';
import { isMobileDevice, ServerError, useConfigContext } from '@omq/shared';

import { Search } from '../components/search/search';
import { QuestionNavigation } from '../components/navigation/question-navigation';
import { QuestionList } from '../components/question-list/question-list';
import { useCanonical } from '../hooks/canonical';
import type { HelpQuestion } from '../api/help';
import { ErrorView } from './error-view';
import { useState } from 'react';

/**
 * Type for component properties.
 */
type DetailViewProps = {
  question: HelpQuestion;
  onSearchChange: (value: string) => void;
  onRootClick: () => void;
  placeholder: Placeholder;
};

/**
 * Detail view for question.
 *
 * Only shows a single question.
 * On mobile, as a modal; on larger screens as expanded item, which can't be closed.
 *
 * Also shows a navigation bar, with root item, and question title.
 *
 * @param {DetailViewProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {JSX.Element}
 */
export function DetailView({
  question,
  onSearchChange,
  onRootClick,
  placeholder,
}: DetailViewProps): JSX.Element {
  const config = useConfigContext();
  const [currentError, setCurrentError] = useState<ServerError | null>(null);

  // on mobile, it's important to leave detail view with
  // click handler, otherwise there is no chance to leave detail view
  // since there is no navigation bar
  const onQuestionClick = () => {
    isMobileDevice() || config.answerDisplayType === ConfigAnswerDisplayTypes.POPUP
      ? onRootClick()
      : null;
  };

  // add a canonical link for question
  useCanonical(question, config.isCanonicalActive);

  if (currentError != null) {
    return <ErrorView errorMessage={config.loc(`_error.${currentError.errorType}`)} />;
  }

  return (
    <Fragment>
      <div className={config.generateClassName('help-container')}>
        <QuestionNavigation title={question.body} onRootClick={onRootClick} />
      </div>

      <div className={config.generateClassName('help-container')}>
        <Search value="" autoCompleteItems={[]} onChange={onSearchChange} />
      </div>

      <div className={config.generateClassName('help-container')}>
        <QuestionList
          isInDetailView={true}
          questions={[question]}
          onQuestionClick={onQuestionClick}
          selectedQuestionId={question.id}
          placeholder={placeholder}
          onError={setCurrentError}
        />
      </div>
    </Fragment>
  );
}
