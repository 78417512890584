import { Backend } from '../backend';
import type { Placeholder } from '@omq/types';

/**
 * API to load initial data.
 *
 * @type {{getInitialData: (<T>function(Backend, (number|null)=): Promise<T>)}}
 */
export const InitApi = {
  /**
   * Load initial data.
   *
   * @template T
   * @param {Backend} backend - backend object
   * @param {Placeholder} placeholder - placeholder for answers
   * @param {{}} params - url params
   *
   * @returns {Promise<T>}
   */
  getInitialData: <T>(
    backend: Backend,
    placeholder: Placeholder = {},
    params?: {} | null,
  ): Promise<T> => {
    const options: { params?: any } = {};

    if (params != null) {
      options.params = params;
    }

    return backend.post<T>(
      'init',
      {
        values: placeholder,
      },
      options,
    );
  },
};
