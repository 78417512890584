import { useEffect } from 'react';

import { ConfigURLPatternTypes } from '@omq/types';
import { useConfigContext } from '@omq/shared';

import type { URLChangeCallback, HelpURLParams } from '../stores/url-store';
import { URLStore } from '../stores/url-store';

type UpdateURLFuncType = (url: string, urlPatternType?: ConfigURLPatternTypes) => void;
/**
 * Hook to handle URL param changes.
 *
 * @param {URLChangeCallback} changeHandler - callback handler for url param changes.
 *
 * @returns {[HelpURLParams, (params: HelpURLParams) => void]}
 */
export const useURLParams: (
  changeHandler?: URLChangeCallback,
) => [HelpURLParams, UpdateURLFuncType] = (changeHandler) => {
  const config = useConfigContext();
  // read current values
  const urlParams = URLStore.readURLParams(config.urlPatternType);

  // add url change listener
  useEffect(() => {
    if (changeHandler == null) {
      return;
    }

    // subscribe
    const urlChangeHandler = URLStore.registerURLParamChanges(changeHandler);

    // unsubscribe
    return () => {
      URLStore.unregisterURLParamChanges(urlChangeHandler);
    };
  }, [changeHandler]);

  // create update function
  const updateURL = (
    url: string,
    urlPatternType: ConfigURLPatternTypes = ConfigURLPatternTypes.QUERY_PARAM,
  ) => {
    URLStore.writeURL(url, urlPatternType);
  };

  // return params & update function
  return [urlParams, updateURL];
};
