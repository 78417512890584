import type { AutoCompleteItem } from '@omq/types';

/**
 * Returns the text of the current auto complete item.
 * Or null, if no item is selected.
 *
 * @returns {string|null}
 */
export function useAutoCompleteValue(
  items: Array<AutoCompleteItem>,
  idx: number,
): string | null | undefined {
  // get current item
  const autoCompleteItem = items[idx];

  // return string with all query content or null
  return autoCompleteItem != null
    ? autoCompleteItem.query.map((tag) => tag.content).join(' ')
    : null;
}
