import { $PropertyType } from 'utility-types';
import { useContext, createContext } from 'react';
import type { Cookie } from '../hooks/cookie';
import { cookieIsExpired, useCookie } from '../hooks/cookie';

/**
 * Type for component properties.
 */
type CookieSessionWrapperProps = {
  sessionId: number;
  name: string;
  cookieIsEnabled?: boolean;
  children: Array<JSX.Element> | JSX.Element;
};

type CookieSessionContextType = {
  cookie: Cookie | null;
  checkIfSessionIsValid: () => void;
  writeCookieContent: (content: $PropertyType<Cookie, 'content'>) => void;
};

const CookieSessionContext = createContext(null);

export function useCookieSessionContext(): CookieSessionContextType {
  const context = useContext(CookieSessionContext);
  if (context == null) {
    throw new Error(
      'CookieSessionContext not available. Did you forget to add CookieSessionWrapper in your component tree?',
    );
  }

  return context;
}

/**
 * Component description.
 *
 * @param {CookieSessionWrapperProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {JSX.Element}
 */
export function CookieSessionWrapper(props: CookieSessionWrapperProps): JSX.Element {
  const { sessionId, name, cookieIsEnabled, children } = props;

  const [cookie, setCookie] = useCookie(name);

  // special case
  // if cookie option has been enabled by hosting website
  // (cookie is null, but session exists)
  // then create cookie
  if (cookieIsEnabled === true && cookie == null && sessionId != null) {
    setCookie(sessionId);
  }

  // special case
  // if cookie option has been disabled by hosting website
  // (cookie is not null)
  if (cookieIsEnabled === false && cookie != null) {
    setCookie(null);
  }

  const checkIfSessionIsValid = () => {
    if (cookieIsExpired(cookie)) {
      setCookie(null);
    }
  };

  const writeCookieContent = (content: { feedback?: any[] }) => {
    setCookie(sessionId, content);
  };

  const contextValue = {
    cookie,
    checkIfSessionIsValid,
    writeCookieContent,
  };

  return (
    <CookieSessionContext.Provider value={contextValue}>{children}</CookieSessionContext.Provider>
  );
}
