/* istanbul ignore file */

// Polyfill for creating CustomEvents on IE9/10/11
// code pulled from:
// https://github.com/d4tocchini/customevent-polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent#Polyfill
(function () {
  if (typeof window === 'undefined') {
    return;
  }
  try {
    let ce = new window.CustomEvent('test', {
      cancelable: true,
    });
    ce.preventDefault();
    if (ce.defaultPrevented !== true) {
      // IE has problems with .preventDefault() on custom events
      // http://stackoverflow.com/questions/23349191
      throw new Error('Could not prevent default');
    }
  } catch (e) {
    const CustomEvent = function (event, p) {
      const params = p || {};
      params.bubbles = !!params.bubbles;
      params.cancelable = !!params.cancelable;
      const evt = document.createEvent('CustomEvent');
      evt.initCustomEvent(event, params.bubbles, params.cancelable, params.detail);
      const origPrevent = evt.preventDefault;
      evt.preventDefault = function () {
        origPrevent.call(this);
        try {
          Object.defineProperty(this, 'defaultPrevented', {
            get: function () {
              return true;
            },
          });
        } catch (e) {
          this.defaultPrevented = true;
        }
      };
      return evt;
    };
    CustomEvent.prototype = window.Event.prototype;
    // @ts-ignore - expose definition to window
    window.CustomEvent = CustomEvent; // expose definition to window
  }
})();
