import { useConfigContext } from '@omq/shared';

import type { NavigationItemType } from './navigation';
import { Navigation } from './navigation';
import type { HelpCategory } from '../../api/help';

/**
 * Type for component properties.
 */
type CategoryNavigationProps = {
  categories: Array<HelpCategory>;

  /**
   * Selection handler.
   * Selected FAQ category is passed.
   */
  onCategorySelection: (category: HelpCategory | null) => void;
};

/**
 * Map categories to navigation items.
 * Generate link for each item.
 *
 * @param {Array<HelpCategory>} items - categories for navigation
 *
 * @returns {Array<NavigationItem>} - navigation items
 */
function mapNavigationItems(items: Array<HelpCategory>): Array<NavigationItemType> {
  return items.map((item, idx) => {
    let href = null;

    // last item has no link
    if (idx !== items.length - 1) {
      // build href
      href = item.url;
    }

    return {
      id: item.id,
      name: item.name,
      href,
    };
  });
}

/**
 * Render navigation with categories, add root item, if
 * categories are not empty.
 *
 * @param {CategoryNavigationProps} props - Component properties
 *
 * @author Florian Walch
 * @since 9.3
 *
 * @returns {JSX.Element}
 */
export function CategoryNavigation(props: CategoryNavigationProps): JSX.Element {
  const { categories, onCategorySelection } = props;

  const config = useConfigContext();

  const rootItem = {
    id: 0,
    href: categories.length > 0 ? '/' : null, // only add link to root item, if it's not the last item
    name: config.loc('navigation_root'),
  };

  // build navigation items
  // with root item
  const items = [rootItem, ...mapNavigationItems(categories)];

  const onClick = (item) => {
    const category = categories.find((cat) => cat.id === item.id) /* istanbul ignore next*/ || null;

    onCategorySelection(category);
  };

  return <Navigation items={items} onItemClick={onClick} styleName="category-navigation" />;
}
