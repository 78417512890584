import { useState, useRef, useImperativeHandle, forwardRef } from 'react';

import type { AutoCompleteItem } from '@omq/types';

import { eventKeyIs, getKeyForEvent, isMobileDevice, Keyboard } from '@omq/shared';

import { SearchInput } from './search-input/search-input';
import { AutoComplete } from './auto-complete/auto-complete';
import { useAutoCompleteValue } from './hooks/auto-complete-value';

import type { SearchInputRefType } from './search-input/search-input';

export type SearchRefType = {
  clearAutoComplete: () => void;
  focus: () => void;
};

type SearchProps = {
  /**
   * Current search value
   */
  value: string;

  /**
   * Handler for input changes
   */
  onChange: (searchValue: string) => void;

  // focus handler
  onFocus?: () => void;

  // blur handler
  onBlur?: () => void;

  onIconClick?: () => void;

  onCloseClick?: (() => void) | null | undefined;

  /**
   * Items to display in component
   */
  autoCompleteItems: Array<AutoCompleteItem>;

  autoFocus?: boolean;

  isDisabled?: boolean;

  placeholder?: string | null;
};

/**
 * Search component for Help.
 * Includes the SearchInput and AutoComplete component, and handles
 * state for both and interactions between components.
 *
 * Example:
 *   <Search value={searchValue}
 *           autoCompleteItems={[]}
 *           onChange={fn}/>
 *
 * @author Florian Walch
 * @since 9.2
 */
const Search = function (props: SearchProps, ref): JSX.Element {
  const {
    value,
    autoFocus,
    isDisabled,
    onChange,
    onFocus,
    onBlur,
    autoCompleteItems,
    onIconClick,
    onCloseClick,
    placeholder,
  } = props;

  const isMobile = isMobileDevice();

  const [autoCompleteIndex, setAutoCompleteIndex] = useState(-1);
  const [autoCompleteIsVisible, setAutoCompleteIsVisible] = useState(false);

  const autoCompleteValue = useAutoCompleteValue(autoCompleteItems, autoCompleteIndex);

  // reference to the real input element
  const searchInputRef = useRef<SearchInputRefType | null>(null);

  /**
   * Handle key events of SearchInput component.
   *
   * @param {KeyboardEvent<HTMLInputElement>} event - Key event
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    const field = event.currentTarget;
    const pressedKey = getKeyForEvent(event);

    // return key, call auto complete handler that current idx was selected
    if (eventKeyIs(pressedKey, Keyboard.KEYBOARD_KEYS.ENTER)) {
      event.preventDefault();
      handleSearchInputReturn();
      return;
    }

    // only notify if cursor is at the end
    if (field.selectionEnd !== field.value.length) {
      setAutoCompleteIndex(-1);
      return;
    }

    // arrow up key, update auto complete idx
    if (eventKeyIs(pressedKey, Keyboard.KEYBOARD_KEYS.ARROW_UP)) {
      handleSearchInputArrowUp();

      // do not stop default event handling if idx is -1
      // user still needs to use arrow key to navigate in input field.
      /* istanbul ignore else */
      if (autoCompleteIndex !== -1) {
        event.preventDefault();
      }

      return;
    }

    // handle -> key
    if (eventKeyIs(pressedKey, Keyboard.KEYBOARD_KEYS.ARROW_RIGHT)) {
      event.preventDefault();
      handleSearchInputReturn();
      return;
    }

    // arrow down key, update auto complete idx
    /* istanbul ignore else */
    if (eventKeyIs(pressedKey, Keyboard.KEYBOARD_KEYS.ARROW_DOWN)) {
      event.preventDefault();
      handleSearchInputArrowDown();
    }
  };

  /**
   * Handle return (enter) keys
   */
  const handleSearchInputReturn = () => {
    // if no auto complete item is active
    // search for current input field value
    if (autoCompleteIndex === -1) {
      // hide auto complete
      setAutoCompleteIsVisible(false);

      // blur input field
      /* istanbul ignore else */
      if (searchInputRef.current) {
        searchInputRef.current.blur();
      }

      return;
    }

    // trigger selection
    /* istanbul ignore else */
    if (autoCompleteValue != null) {
      handleAutoCompleteSelect(autoCompleteValue);
    }

    // reset index
    setAutoCompleteIndex(-1);
  };

  /**
   * Handle arrow down key press
   */
  const handleSearchInputArrowDown = () => {
    // check if there are more items to select
    /* istanbul ignore else */
    if (autoCompleteIndex < autoCompleteItems.length - 1) {
      setAutoCompleteIndex(autoCompleteIndex + 1);
    }
  };

  /**
   * Handle arrow up key press
   */
  const handleSearchInputArrowUp = () => {
    // if there are no more items
    /* istanbul ignore else */
    if (autoCompleteIndex >= 0) {
      setAutoCompleteIndex(autoCompleteIndex - 1);
    }
  };

  /**
   * Handle auto complete item selection.
   *
   * @param {string} text - text of selected auto complete item
   */
  const handleAutoCompleteSelect = (text: string): void => {
    // notify parent about new search text
    onChange(`${text} `);

    setAutoCompleteIndex(-1);

    // restore focus (eventually lost by click selection)
    /* istanbul ignore else */
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  };

  // get search value - if auto complete item is selected, use auto complete text
  // otherwise current search value
  const searchInputValue = autoCompleteValue != null ? autoCompleteValue : value;

  // show auto complete if flag is set and if there are items
  const showAutoComplete = autoCompleteItems.length > 0 && autoCompleteIsVisible;

  // focus handler
  const handleFocus = () => {
    // show auto complete
    setAutoCompleteIsVisible(true);

    // call callback
    if (onFocus != null) {
      onFocus();
    }
  };

  // blur handler
  const handleBlur = () => {
    // hide auto complete
    setAutoCompleteIsVisible(false);

    // reset index
    setAutoCompleteIndex(-1);

    // call callback
    if (onBlur != null) {
      onBlur();
    }
  };

  // handle search value changes
  const handleChange = (searchValue) => {
    setAutoCompleteIndex(-1);
    onChange(searchValue);
  };

  // create public interface for parent
  useImperativeHandle(ref, () => ({
    // public interface to clear auto complete
    // call blur - will reset auto complete
    clearAutoComplete: () => {
      if (searchInputRef.current) {
        searchInputRef.current.blur();
      }
    },

    // forward focus call to input element
    focus: () => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
      }
    },
  }));

  return (
    <SearchInput
      value={searchInputValue}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
      placeholder={placeholder}
      ref={searchInputRef}
      onFocus={handleFocus}
      onBlur={isMobile ? undefined : handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onIconClick={onIconClick}
      onCloseClick={onCloseClick}>
      {showAutoComplete && (
        <AutoComplete
          items={autoCompleteItems}
          activeItemIndex={autoCompleteIndex}
          onAutoCompleteSelect={handleAutoCompleteSelect}
        />
      )}
    </SearchInput>
  );
};

const ForwardSearch = forwardRef<SearchRefType, SearchProps>(Search);
export { ForwardSearch as Search };
