import { useState, useEffect } from 'react';

import { MediaQueryWrapper } from '@omq/shared';

/**
 * Check device screen size, and force re-render on breakpoint changes.
 */
export function useMediaQuery() {
  // setup empty state
  // calling set state method will force a rerender
  const [, forceUpdate] = useState();

  useEffect(() => {
    // setup media query listener
    // @ts-ignore type not needed, as we only use the listener
    const mediaQuery = new MediaQueryWrapper('(min-width: 992px)', forceUpdate);

    // unsubscribe on unmount
    return () => {
      mediaQuery.removeListener();
    };
  }, [forceUpdate]);
}
