import axios from 'axios';

import type { Axios, AxiosRequestConfig, CancelTokenSource } from 'axios';
import { getBaseUrl } from '../..';

export type ServerError = {
  errorType: string;
  errorMessage: string;
};

type RequestHeaders = Record<string, string>;

export class ResultWithHeaders<T> {
  data: T;
  headers: RequestHeaders;

  constructor(data: T, headers: RequestHeaders) {
    this.data = data;
    this.headers = headers;
  }
}

/**
 * Backend class that performs requests to a server.
 * This backend class wraps the axios http client library.
 *
 * @class
 * @author Florian Walch
 * @since 9.2
 */
export class Backend {
  baseURL: string;

  /**
   * axios client instance
   */
  axios: Axios;

  sessionInterceptor: number;

  sessionId: number;

  static getCancelToken(): CancelTokenSource {
    return axios.CancelToken.source();
  }

  /**
   * Constructor.
   *
   * Creates axios instance.
   *
   * @param {string} account - Name/URL of account
   * @param {string|null} apiKey - Auth API Key
   * @param {string} path - additional path for base url
   * @param {{}} headers - additional default headers
   * @param {{}} useAPI - enable/disable calls to API path
   */
  constructor(
    account: string,
    apiKey: string | null,
    path: string = '',
    headers: RequestHeaders = {},
    useAPI: boolean = true,
  ) {
    // create base url based on account
    const baseURL = getBaseUrl(account) || '';

    let apiPath = path == null ? '' : path;

    // add trailing slash if path is given and slash is missing
    if (apiPath !== '' && apiPath[apiPath.length] !== '/') {
      apiPath = `${apiPath}/`;
    }

    this.baseURL = baseURL;

    // create new instance
    this.axios = axios.create({
      baseURL: `${baseURL}/${useAPI ? 'api/' : ''}${apiPath}`,
      headers: {
        // $FlowExpectedError
        common: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          ...headers,
        },
      },
    });

    if (apiKey != null) {
      // add api_key param to every request
      this.axios.interceptors.request.use((config) => {
        config.params = { ...config.params, api_key: apiKey };

        return config;
      });
    }
  }

  /**
   * Update session for further requests.
   *
   * @param {number} sessionId - ID of session
   */
  setSession(sessionId: number): void {
    this.sessionId = sessionId;

    if (this.sessionInterceptor != null) {
      this.axios.interceptors.request.eject(this.sessionInterceptor);
    }

    // add axios interceptor - will add session param to all requests.
    // @see https://github.com/axios/axios#interceptors
    this.sessionInterceptor = this.axios.interceptors.request.use((config) => {
      config.params = { ...config.params, session: sessionId };

      return config;
    });
  }

  /**
   * Performs GET request via `axios.get`.
   * Returns a promise which resolves with the data
   * of response.
   *
   * Passed url should be relative to the base url.
   * In this case here, it's /api/help/.
   *
   * Example:
   *   // calls /api/help/questions/1
   *   const backend = new Backend('test', '12345');
   *   backend
   *     .get<Question>('questions/1', ...)
   *     .then(question => ...)
   *     .catch(message => ...);
   *
   * @template T
   * @param {string} url - url, relative to specified base.
   * @param {AxiosRequestConfig<T>} config - request config
   *
   * @returns {Promise<T>}
   */
  get<T>(url: string, config?: AxiosRequestConfig<T>): Promise<T> {
    // wrap in promise
    return new Promise((resolve, reject) => {
      // make request
      this.axios
        .get(url, config) // handle result
        .then((response) => resolve(response.data)) // handle error
        .catch((error) => {
          if (!axios.isCancel(error)) {
            reject(error);
          }
        });
    });
  }

  /**
   * Performs GET request via `axios.get`.
   * Returns a promise which resolves with an array of the data
   * of the response as well as its headers.
   *
   * Passed url should be relative to the base url.
   * In this case here, it's /api/help/.
   *
   * Example:
   *   // calls /api/help/questions/1
   *   const backend = new Backend('test', '12345');
   *   backend
   *     .get<Question>('questions/1', ...)
   *     .then(question => ...)
   *     .catch(message => ...);
   *
   * @template T
   * @param {string} url - url, relative to specified base.
   * @param {?AxiosRequestConfig<T>} config - request config
   *
   * @returns {Promise<T>}
   */
  getWithHeaders<T>(url: string, config?: AxiosRequestConfig<T>): Promise<ResultWithHeaders<T>> {
    // wrap in promise
    return new Promise((resolve, reject) => {
      // make request
      this.axios
        .get(url, config) // handle result
        .then((response) => {
          resolve(new ResultWithHeaders(response.data, response.headers));
        }) // handle error
        .catch((error) => {
          if (!axios.isCancel(error)) {
            reject(error);
          }
        });
    });
  }

  /**
   * Performs POST request via `axios.post`.
   * Returns a promise which resolves with the data
   * of response.
   *
   * Passed url should be relative to the base url.
   * In this case here, it's /api/help/.
   *
   * Example:
   *   // calls /api/help/search
   *   const backend = new Backend('test', '12345');
   *   const data = {text: 'account'};
   *   const params = {language: 'EN'};
   *
   *   backend
   *     .post<QuestionSearchResult>('search', data, params)
   *     .then(result => ...)
   *     .catch(message => ...);
   *
   * @template T
   * @param {string} url - url, relative to specified base.
   * @param {{}} data - request body
   * @param {?AxiosRequestConfig<T>} config - request config
   *
   * @returns {Promise<T>}
   */
  post<T>(url: string, data: unknown = {}, config?: AxiosRequestConfig<T>): Promise<T> {
    // wrap in promise
    return new Promise((resolve, reject) => {
      // make request
      this.axios
        .post(url, data, config) // handle result
        .then((response) => resolve(response.data)) // handle error
        .catch((error) => {
          if (!axios.isCancel(error)) {
            reject(error);
          }
        });
    });
  }

  /**
   * Performs PUT request via `axios.put`.
   * Returns a promise which resolves with the data
   * of response.
   *
   * Passed url should be relative to the base url.
   * In this case here, it's /api/help/.
   *
   * Example:
   *   // calls /api/help/search
   *   const backend = new Backend('test', '12345');
   *   const data = {text: 'account'};
   *   const params = {language: 'EN'};
   *
   *   backend
   *     .put<QuestionSearchResult>('search', data, params)
   *     .then(result => ...)
   *     .catch(message => ...);
   *
   * @template T
   * @param {string} url - url, relative to specified base.
   * @param {{}} data - request body
   * @param {?AxiosRequestConfig<T>} config - request config
   *
   * @returns {Promise<T>}
   */
  put<T>(url: string, data: unknown = {}, config?: AxiosRequestConfig<T>): Promise<T> {
    // wrap in promise
    return new Promise((resolve, reject) => {
      // make request
      this.axios
        .put(url, data, config) // handle result
        .then((response) => resolve(response.data)) // handle error
        .catch((error) => {
          if (!axios.isCancel(error)) {
            reject(error);
          }
        });
    });
  }

  delete<T>(url: string, config?: AxiosRequestConfig<T>): Promise<void> {
    return new Promise((resolve, reject) => {
      this.axios
        .delete(url, config)
        .then(() => resolve())
        .catch((error) => {
          if (!axios.isCancel(error)) {
            reject(error);
          }
        });
    });
  }
}
