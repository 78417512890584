import type { FaqCategory } from './faq-category';

export enum ConfigAnswerDisplayTypes {
  INLINE = 'INLINE',
  POPUP = 'POPUP',
}

export enum ConfigCategoryDisplayTypes {
  PERMANENT = 'PERMANENT',
  DISAPPEAR = 'DISAPPEAR',
}

export enum ConfigMobileSearchDisplayTypes {
  INLINE = 'INLINE',
  POPUP = 'POPUP',
}

export enum ConfigURLPatternTypes {
  QUERY_PARAM = 'QUERY_PARAM',
  PATH = 'PATH',
}

/**
 * Type for Config.
 *
 * Includes, UI messages & other config provided by server.
 */
export type Config = {
  messages: Record<string, string>;
  isAutoFocusActive: boolean;
  isDefaultCookieActive: boolean;
  isFeedbackActive: boolean;
  isPrintingActive: boolean;
  isPoweredByActive: boolean;
  answerDisplayType: ConfigAnswerDisplayTypes;
  categoryDisplayType: ConfigCategoryDisplayTypes;
  mobileSearchDisplayType: ConfigMobileSearchDisplayTypes;
  urlPatternType: ConfigURLPatternTypes;
  cssIdentifier: string;
  categories: Array<FaqCategory>;
  isCanonicalActive: boolean;
};

/**
 * Type for answer placeholders
 */
export type Placeholder = Record<string, unknown>;
