import { MouseEvent } from 'react';
/**
 * Helper function to prevent loosing focus of search input/auto complete.
 * Used as a mouseDown handler.
 *
 * Prevent browser default behaviour on mouseDown
 * bc it would cause blur event being triggered on search field,
 * which would lead to a state change in the search component and
 * a rerender of the app.
 * this results in not calling the click event handler
 * (react disables events during re-renders)
 *
 * @param {MouseEvent} event - mouse event
 * @param {string} selector - selector for active element
 */
export const keepAutoComplete = (event: MouseEvent, selector: string) => {
  // prevent default handling only if element that matches given selector is active
  if (document.activeElement != null && document.activeElement.matches(selector)) {
    // prevent blur events
    event.preventDefault();
  }
};
