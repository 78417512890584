import { EventDispatcher } from '@omq/shared';

import type { HelpCategory, HelpSearchResult } from '../api/help';
import { EventNames } from '@omq/shared/src/event/event-dispatcher';
import type { FaqCategory } from '@omq/types';

export const HelpEventNames = {
  CATEGORY_CLICK_EVENT: 'onOmqCategoryClick',
  FAQ_CATEGORIES_LOADED_EVENT: 'onOmqFAQCategoriesLoaded',
};

/**
 * Class that dispatches custom event on provided element.
 *
 * @class
 * @since 9.3
 * @author Florian Walch
 */
export class HelpEvents extends EventDispatcher<HelpSearchResult> {
  /**
   * Dispatch a category that has been clicked.
   *
   * @param {HelpCategory} category - clicked category
   */
  dispatchCategoryClick(category: HelpCategory) {
    this.dispatchEvent(HelpEventNames.CATEGORY_CLICK_EVENT, {
      category,
    });
  }

  /**
   * Dispatch event for received search result.
   *
   * @param {string} searchValue - text used for search
   * @param {HelpSearchResult} result - contact search result
   */
  dispatchSearchLoaded(searchValue: string, result: HelpSearchResult) {
    const { questions, autoComplete } = result;

    this.dispatchEvent(EventNames.SEARCH_LOADED_EVENT, {
      questions,
      autoComplete,
      searchValue,
    });
  }

  /**
   * Dispatch event for received categories.
   *
   * @param {Array<HelpCategory>} categories - categories
   */
  dispatchCategoriesLoaded(categories: Array<FaqCategory>) {
    this.dispatchEvent(EventNames.CATEGORIES_LOADED_EVENT, {
      categories,
    });
  }

  /**
   * Dispatch event for received FAQ categories.
   *
   * @param {Array<Array<HelpCategory>>} categories - categories
   */
  dispatchFAQCategoriesLoaded(categories: Array<Array<HelpCategory>>) {
    this.dispatchEvent(HelpEventNames.FAQ_CATEGORIES_LOADED_EVENT, {
      categories,
    });
  }
}
