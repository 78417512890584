import { useCallback } from 'react';

import { SearchDelayTypes, useBackendContext, useDelayedSearch } from '@omq/shared';
import type { Placeholder } from '@omq/types';

import type { HelpSearchParams, HelpSearchResult } from '../api/help';
import { HelpAPI } from '../api/help';

const defaultSearchResult = {
  questions: [],
  autoComplete: [],
  nextPage: -1,
};

/**
 * Hook to load auto complete.
 * Returns auto complete and questions from server for the given
 * search value & params.
 *
 * @param {string} searchValue - search input value
 * @param {Placeholder} placeholder - placeholder for answers
 * @param {HelpSearchParams} searchParams - search params
 *
 * @returns {HelpSearchResult}
 */
export function useAutoComplete(
  searchValue: string,
  placeholder: Placeholder,
  searchParams: HelpSearchParams,
): HelpSearchResult {
  // get backend context
  const backend = useBackendContext();

  const searchFunc = useCallback(
    (cancelTokenSource) => {
      return HelpAPI.searchQuestions(
        backend,
        searchValue,
        placeholder,
        searchParams,
        cancelTokenSource.token,
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchValue, searchParams, placeholder],
  );

  const transformResult = (currentResult, result) => {
    // if response is initial page
    // return response
    if (searchParams.page == null) {
      return result;
    }

    // for consecutive pages
    // merge response
    return {
      nextPage: result.nextPage,
      questions: [...currentResult.questions, ...result.questions],
      autoComplete: result.autoComplete,
    };
  };

  return useDelayedSearch<HelpSearchResult>({
    type: SearchDelayTypes.HELP,
    searchValue,
    searchFunc,
    transformResult,
    defaultResult: defaultSearchResult,
  });
}
