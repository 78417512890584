import { useState, useEffect, useMemo } from 'react';
import type { InitialData, Placeholder } from '@omq/types';

import { useBackendContext } from '../components/backend/backend-wrapper';
import { InitApi } from '../backend/api/init';

/**
 * Hook to load initial app data.
 */
export function useInitialData<T extends InitialData>(
  placeholder: Placeholder,
  session: number | null,
  params?: {},
): T | null {
  const [data, setData] = useState<T | null>(null);

  // get backend
  const backend = useBackendContext();

  // memoize request data
  const requestParams = useMemo(() => {
    return { ...params, session };
  }, [params, session]);

  useEffect(() => {
    // load initial data from server
    InitApi.getInitialData<T>(backend, placeholder, requestParams)
      .then((result) => {
        backend.setSession(result.session.id);
        setData(result);
      })
      .catch((
        error, // throw error
      ) =>
        setData(() => {
          throw new Error(error.errorMessage);
        }),
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backend, requestParams]);

  return data;
}
