import { Component } from 'react';

import './error-boundary.less';
import { ErrorDetails } from '../../utils/error_handling';

/**
 * Type for error boundary properties.
 */

type ErrorBoundaryProps = {
  onError?: (error: Error, details: ErrorDetails | null | undefined) => void;
  renderError?: (error: Error) => JSX.Element;
  children: JSX.Element;
};

/**
 * Type for error boundary state
 */
type ErrorBoundaryState = {
  error: Error | null;
};

/**
 * Catch react related errors.
 * Display errors or children.
 *
 * @class
 * @author Florian Walch
 * @since 9.3
 */
export class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    error: null,
  };

  componentDidCatch(error: Error): void {
    const { onError } = this.props;
    if (onError != null) {
      onError(error, null);
    }

    this.setState({
      error,
    });
  }

  render() {
    const { renderError } = this.props;
    const { error } = this.state;

    if (error) {
      if (renderError != null) {
        return renderError(error);
      }

      return (
        <div className="omq error-boundary">
          <h1 className="error-boundary__headline">Backend error</h1>
          <p className="error-boundary__text">An error occurred</p>
          <p className="error-boundary__message">{error.message}</p>
        </div>
      );
    }

    return this.props.children;
  }
}
